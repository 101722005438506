// Angular Imports
import { NgModule } from '@angular/core';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';

// Project Imports
import { AspenAuthService } from './aspen-auth.service';

@NgModule({
    imports: [],
    providers: [AspenAuthService, provideHttpClient(withInterceptorsFromDi())]
})
export class AuthModule {}
