// Angular Imports
import { NgModule } from '@angular/core';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';

// Fuse Imports
import { FuseFullscreenModule } from '@fuse/components/fullscreen';
import { FuseNavigationModule } from '@fuse/components/navigation';
import { UserModule } from 'app/layout/common/user/user.module';

// Project Imports
import { SharedModule } from 'app/shared/shared.module';
import { VizioLayoutComponent } from './vizio.component';
import { AspenUserModule } from '@shared/aspen-user/aspen-user.module';
import { AspenFooterModule } from '@app/layout/layouts/vizio/aspen-footer/aspen-footer.module';
import { AspenSideNavModule } from '@app/layout/common/aspen-side-nav/aspen-side-nav.module';

@NgModule({
    declarations: [VizioLayoutComponent],
    exports: [VizioLayoutComponent],
    imports: [
        RouterModule,
        MatButtonModule,
        MatDividerModule,
        MatIconModule,
        MatMenuModule,
        FuseFullscreenModule,
        FuseNavigationModule,
        UserModule,
        AspenUserModule,
        SharedModule,
        AspenFooterModule,
        AspenSideNavModule
    ],
    providers: [provideHttpClient(withInterceptorsFromDi())]
})
export class VizioLayoutModule {}
