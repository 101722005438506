// Angular Imports
import { Injectable } from '@angular/core';

// Project Imports
import { StateServiceBase } from '@core/state-service/state-service.base';
import { IWalmartStockInfo } from '@shared/services/walmart-stock.service';
import { Observable } from 'rxjs';

export interface IAppState {
    WalmartStock?: IWalmartStockInfo;
}

const initialState: IAppState = {
    WalmartStock: null
};

@Injectable({
    providedIn: 'root'
})
export class AppStateService extends StateServiceBase<IAppState> {
    public walmartStock$: Observable<IWalmartStockInfo> = this.select(
        (state) => state.WalmartStock
    );

    constructor() {
        super(initialState);
    }

    public setWalmartStock(stockInfo: IWalmartStockInfo): void {
        this.setState({
            WalmartStock: stockInfo
        });
    }
}
