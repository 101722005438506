// Angular Imports
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

// Third-Party Imports
import { Observable, tap, switchMap } from 'rxjs';

// Project Imports
import { AppStateService, IAppState } from '@app/app-state.service';
import { WalmartStockService } from '@shared/services/walmart-stock.service';

@Injectable({
    providedIn: 'root'
})
export class AppStateResolver {
    constructor(
        private appStateService: AppStateService,
        private walmartStockService: WalmartStockService
    ) {}

    public resolve(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<IAppState> {
        return this.walmartStockService.getStockInfo().pipe(
            tap((stockInfo) => {
                this.appStateService.setWalmartStock(stockInfo);
            }),
            switchMap((stockInfo) => this.appStateService.state$)
        );
    }
}
