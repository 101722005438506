// Angular Imports
import { Component, OnDestroy, OnInit, Signal } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';

// Third Party Imports
import { filter, Observable, Subscription } from 'rxjs';

// Project Imports
import { AppStateService } from '@app/app-state.service';
import { IStockInfo } from './aspen-footer/aspen-footer.types';
import { EasterEggsStateService } from '@shared/easter-eggs/services/easter-eggs-state.service';
import { AspenNavigationStateService } from '@core/navigation/aspen-navigation-state.service';

@Component({
    selector: 'vizio-layout',
    templateUrl: './vizio.component.html',
    styleUrls: ['./vizio.component.scss']
})
export class VizioLayoutComponent implements OnInit, OnDestroy {
    public pageTitle: string;
    public subscriptions: Subscription[] = [];

    public walmartStock$: Observable<IStockInfo> = this._appStateService.walmartStock$;

    public isSpooky: Signal<boolean> = this.eeStateService.isSpooky;
    public isWinter: Signal<boolean> = this.eeStateService.isWinter;

    constructor(
        private _appStateService: AppStateService,
        private activatedRoute: ActivatedRoute,
        private router: Router,
        private eeStateService: EasterEggsStateService,
        private aspenNavStateService: AspenNavigationStateService
    ) {}

    public ngOnInit(): void {
        this.pageTitle =
            this._getPageTitle() === 'ASPEN'
                ? this._getPageTitle()
                : this._getPageTitle().toUpperCase().slice(8);

        this.subscriptions.push(
            this.router.events
                .pipe(filter((evt) => evt instanceof NavigationEnd))
                .subscribe((evt) => {
                    this.pageTitle = this._getPageTitle();
                })
        );
    }

    public ngOnDestroy(): void {
        this.subscriptions.forEach((sub) => {
            sub.unsubscribe();
        });
    }

    public clickMenu(): void {
        this.aspenNavStateService.toggleNav();
    }

    private _getPageTitle(): string {
        let route = this.activatedRoute;

        while (route.firstChild) {
            route = route.firstChild;
        }

        return route?.snapshot?.data?.title || route.snapshot.title || '';
    }
}
